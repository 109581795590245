import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { switchMap, tap } from 'rxjs';

import { GeoPhotoCategoryDTO, GeoPhotoCategoryListDTO } from '@yuno/api/interface';

import { CategoriesService } from '../services';

export type getSelectedCategory = {
	appId: string;
	categoryId: string;
};

type GeoPhotoListState = {
	data: GeoPhotoCategoryListDTO[];
	selected: GeoPhotoCategoryDTO | null;
	isLoading: boolean;
	dataLoaded: boolean;
};

const initialState: GeoPhotoListState = {
	data: [],
	selected: null,
	isLoading: false,
	dataLoaded: false
};

export const GeoPhotoListStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withComputed(({ data, selected }) => ({
		id: computed(() => selected()?.id)
	})),
	withMethods((state, service = inject(CategoriesService)) => ({
		reset: () => patchState(state, initialState),
		// select(query: string) {
		// 	// const selected = state.data().find(model => model._id === query) || null;
		// 	// patchState(state, {
		// 	// 	selected
		// 	// });
		// },
		select: rxMethod<getSelectedCategory>(query =>
			query.pipe(
				tap(() => patchState(state, { isLoading: true, dataLoaded: false })),
				switchMap(category =>
					service.getCategory(category.appId, category.categoryId).pipe(
						tapResponse({
							next: data => {
								patchState(state, { selected: data });
							},
							error: () => {
								patchState(state, {
									data: initialState.data,
									isLoading: false,
									dataLoaded: true
								});
							}
						})
					)
				)
			)
		),
		getApp: rxMethod<string>(appId =>
			appId.pipe(
				tap(() => patchState(state, { isLoading: true, dataLoaded: false })),
				switchMap(appId =>
					service.getApp(appId).pipe(
						tapResponse({
							next: data => {
								patchState(state, { data, isLoading: false, dataLoaded: true });
							},
							error: () => {
								patchState(state, {
									data: initialState.data,
									isLoading: false,
									dataLoaded: true
								});
							}
						})
					)
				)
			)
		)
	}))
);
