import { Injectable, inject } from '@angular/core';
import { Observable, take } from 'rxjs';

import { ApiObservableService } from '@yuno/angular/api';
import { GeoPhotoCategoryDTO, GeoPhotoCategoryListDTO } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class CategoriesService {
	private readonly api = inject(ApiObservableService);

	getApp(appId: string): Observable<GeoPhotoCategoryListDTO[]> {
		return this.api
			.get<GeoPhotoCategoryListDTO[]>(`geophotos/categories/portal/${appId}`)
			.pipe(take(1));
	}

	getCategory(appId: string, categoryId: string): Observable<GeoPhotoCategoryDTO> {
		return this.api
			.get<GeoPhotoCategoryDTO>(`geophotos/categories/portal/${appId}/${categoryId}`)
			.pipe(take(1));
	}
}
