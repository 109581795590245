import * as ExifReader from 'exifreader';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { tapResponse } from '@ngrx/operators';
import { LngLat } from 'maplibre-gl';
import { Observable, filter, map, switchMap, take } from 'rxjs';

import { ApiCDNObservableService, ApiObservableService } from '@yuno/angular/api';
import { ControlsOf, LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import { MessageService } from '@yuno/angular/notifications';
import { CdnData, CdnFile, GeoPhoto, storageApiResponseDto } from '@yuno/api/interface';
import { YunoMapService } from '@yuno/yuno/core';
import { AppStore } from '@yuno/yuno/features/functions';

export interface GeoPhotoForm {
	id: FormControl<string>;
	rotation: FormControl<number>;
	title: FormGroup<LanguageFormType>;
	description: FormGroup<LanguageFormType>;

	_id: FormControl<string | undefined>;
	categoryId: FormControl<string>;
	public: FormControl<boolean>;
	coordinates: FormArray<FormControl<number>>;
	file?: FormGroup<ControlsOf<CdnFile> | undefined>;
}

@Injectable({
	providedIn: 'root'
})
export class GeoPhotoItemService {
	private readonly api = inject(ApiObservableService);
	private readonly cdn = inject(ApiCDNObservableService);
	private readonly appStore = inject(AppStore);
	private readonly message = inject(MessageService);
	readonly mapService = inject(YunoMapService);

	$currentUserLocation = signal<{ lat: number; lng: number } | undefined>(undefined);
	$currentUserRotation = signal<number>(0);

	$file = signal<File | undefined>(undefined);
	$fileUploadPercentage = signal<number>(0);
	$alreadyExistsError = signal<boolean>(false);

	$previewFile = signal<string | undefined>(undefined);
	$exifData = signal<ExifReader.ExpandedTags | undefined>(undefined);

	$center = signal<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
	$bearing = signal<number>(0);

	$locationRequested = signal<boolean>(false);

	overwrite = false;

	form = new FormGroup<GeoPhotoForm>({
		_id: new FormControl({ value: undefined, disabled: true }, { nonNullable: true }),
		id: new FormControl<string>('', { nonNullable: true }),
		categoryId: new FormControl<string>('', { nonNullable: true }),
		public: new FormControl<boolean>(true, { nonNullable: true }),
		rotation: new FormControl<number>(0, { nonNullable: true }),
		coordinates: new FormArray<FormControl<number>>([
			new FormControl<number>(0, { nonNullable: true }),
			new FormControl<number>(0, { nonNullable: true })
		]),
		file: new FormGroup<ControlsOf<CdnFile> | undefined>({
			_id: new FormControl({ value: undefined, disabled: true }, { nonNullable: true }),
			data: new FormGroup<ControlsOf<CdnData> | undefined>({
				fileName: new FormControl(
					{ value: undefined, disabled: true },
					{ nonNullable: true }
				),
				url: new FormControl({ value: undefined, disabled: true }, { nonNullable: true })
			})
		}),
		title: newLanguageFormGroup(),
		description: newLanguageFormGroup()
	});

	get coordinates(): FormArray {
		return this.form.get('coordinates') as FormArray;
	}

	patchCoordinates(coords: LngLat): void {
		this.coordinates.patchValue(coords.toArray());
	}

	patchRotation(rotation: number): void {
		this.form.get('rotation')?.patchValue(rotation);
	}

	setLocationRequested(bool: boolean): void {
		this.$locationRequested.set(bool);
	}

	setFile(file: File): void {
		this.$file.set(file);
	}

	setPreviewFile(str: string): void {
		this.$previewFile.set(str);
	}

	setExifData(exif: ExifReader.ExpandedTags): void {
		this.$exifData.set(exif);
	}

	async selectFile(event: Event): Promise<void> {
		const eventTarget = event.target as HTMLInputElement;
		const list = eventTarget.files as FileList;

		if (!list || !list[0]) {
			return;
		}

		const exifData: ExifReader.ExpandedTags = await ExifReader.load(list[0], {
			async: true,
			expanded: true
		});

		this.form.get('id')?.patchValue(list[0].name);

		this.setFile(list[0]);
		this.setExifData(exifData);

		this.setPreviewFile(URL.createObjectURL(list[0]));

		if (!exifData.gps) {
			this.message.sendToast(
				'This photo does not contain valid GPS data. We will attempt to use your current location instead.',
				'error',
				5
			);
			this.getUserLocation(true);
		} else {
			this.message.sendToast('The location has been determined using GPS data', 'success', 5);
		}
	}

	saveItem(): Observable<GeoPhoto> {
		const file = this.$file();
		const data = this.form.getRawValue() as Partial<GeoPhoto>;
		const appId = this.appStore.appId() as string;

		if (file) {
			return this.saveFile(file, data, appId).pipe(
				take(1),
				tapResponse({
					next: data => {
						return data;
					},
					error: (err: HttpErrorResponse) => {
						if (err.status === 409) {
							this.$alreadyExistsError.set(true);
						}
						this.message.sendToast('Error updating!', 'error');
					}
				}),
				switchMap(resp => {
					return this.api
						.patch<GeoPhoto, { appId: string; data: Partial<GeoPhoto> }>(
							`geophotos/${appId}/${data._id}`,
							{
								appId,
								data: {
									...data,
									file: resp.data
								}
							}
						)
						.pipe(
							take(1),
							tapResponse({
								next: () => {
									this.$file.set(undefined);
									this.$fileUploadPercentage.set(0);

									this.message.sendToast('The GeoPhoto is updated!', 'success');
								},
								error: err => {
									console.error(err);
									this.message.sendToast('Error updating!', 'error');
								}
							})
						);
				})
			);
		}

		return this.api
			.patch<GeoPhoto, { appId: string; data: Partial<GeoPhoto> }>(
				`geophotos/${appId}/${data._id}`,
				{
					appId,
					data
				}
			)
			.pipe(
				take(1),
				tapResponse({
					next: () => {
						this.message.sendToast('The GeoPhoto is updated!', 'success');
					},
					error: err => {
						console.error(err);
						this.message.sendToast('Error updating!', 'error');
					}
				})
			);
	}

	saveFile(
		file: File,
		data: Partial<GeoPhoto>,
		appId: string
	): Observable<storageApiResponseDto> {
		const formData = new FormData();
		formData.append('file', file);

		const coords = this.coordinates.getRawValue();
		const location = {
			latitude: coords[0],
			longitude: coords[1],
			altitude: 0,
			bearing: this.form.get('rotation')?.value
		};

		formData.append('location', JSON.stringify(location));

		// Get the original Extension
		const extention = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
		// We send this because we cannot edit the Name in the FILE to upload
		formData.append('rename', `${data.id as string}.${extention}`);

		if (this.overwrite) {
			formData.append('overwrite', 'true');
		}

		return this.cdn
			.post<storageApiResponseDto, FormData>(`v2/content/upload/${appId}/image`, formData)
			.pipe(
				map(event => {
					this.getEventMessage(event, file);
					return event;
				}),
				filter(resp => resp.type === HttpEventType.Response),
				map(resp => {
					const r = resp as HttpResponse<storageApiResponseDto>;
					if (!r.body) {
						this.message.sendToast('Error updating!', 'error');
						throw Error();
					}

					this.overwrite = false;
					return r.body;
				})
			);
	}

	/** Return distinct message for sent, upload progress, & response events */
	private getEventMessage(event: HttpEvent<storageApiResponseDto>, file: File) {
		switch (event.type) {
			case HttpEventType.Sent:
				return `Uploading file "${file.name}" of size ${file.size}.`;

			case HttpEventType.UploadProgress:
				// Compute and show the % done:
				// eslint-disable-next-line no-case-declarations
				const percentDone = event.total
					? Math.round((100 * event.loaded) / event.total)
					: 0;

				this.$fileUploadPercentage.set(percentDone);
				return `File "${file.name}" is ${percentDone}% uploaded.`;

			case HttpEventType.Response:
				return `File "${file.name}" was completely uploaded!`;

			default:
				return `File "${file.name}" surprising upload event: ${event.type}.`;
		}
	}

	/**
	 * Get the user location and update the map
	 * Should only be called when the original Item does not have coordinates
	 *
	 * @param force {boolean} Force the location request
	 */
	getUserLocation(force?: boolean): void {
		// Only allow to request location once
		if (!force && this.$locationRequested()) {
			return;
		}

		this.setLocationRequested(true);

		this.mapService.getCurrentPosition().subscribe({
			next: position => {
				const location = new LngLat(position.coords.longitude, position.coords.latitude);

				// Only update the location if the current location is 0,0
				const current = this.$center();
				if (!force && current && current.lng !== 0 && current.lat !== 0) {
					return;
				}

				// Update the center and bearing of the Map
				this.$center.set(location);
				this.$bearing.set(position.coords.heading || 0);

				// Patches the form with the new coordinates and rotation
				// This updates the Marker position
				this.patchCoordinates(location);
				this.patchRotation(position.coords.heading || 0);

				// Update the Map Center to directly move to the new location
				// instead of slowly flying to the location
				this.mapService.setCenter(location);
			},
			error: (error: GeolocationPositionError) => this.mapService.userLocationError(error)
		});
	}

	/**
	 * Get the user location and update the map
	 * Should only be called when the original Item does not have coordinates
	 */
	showUserLocation(): void {
		this.mapService.getCurrentPosition().subscribe({
			next: position => {
				const location = new LngLat(position.coords.longitude, position.coords.latitude);

				// Update the center and bearing of the Map
				this.$center.set(location);

				// Patches the form with the new coordinates and rotation
				// This updates the Marker position
				this.$currentUserLocation.set(location);
				this.$currentUserRotation.set(position.coords.heading || 0);
			},
			error: (error: GeolocationPositionError) => this.mapService.userLocationError(error)
		});
	}

	/**
	 * Updates the Marker to use the current location of the user
	 */
	useMyLocation(): void {
		const loc = this.$currentUserLocation();
		if (!loc) {
			return;
		}
		const location = new LngLat(loc.lng, loc.lat);

		// Update the center and bearing of the Map
		this.$center.set(location);
		this.$bearing.set(this.$currentUserRotation());

		// Patches the form with the new coordinates and rotation
		// This updates the Marker position
		this.patchCoordinates(location);
		this.patchRotation(this.$currentUserRotation());

		// Reset the current user location
		this.$currentUserLocation.set(undefined);
		this.$currentUserRotation.set(0);
	}
}
