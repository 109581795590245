import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { switchMap, tap } from 'rxjs';

import { GeoPhoto, generateLanguageObject } from '@yuno/api/interface';

import { CategoryService } from '../services';

type GeoPhotoItemState = {
	data: GeoPhoto[];
	filter: string | undefined;
	preFilter: 'all' | 'public' | 'non-public';
	isLoading: boolean;
};

const initialState: GeoPhotoItemState = {
	data: [],
	filter: undefined,
	preFilter: 'all',
	isLoading: false
};

export type getGeoPhotoData = {
	appId: string;
	categoryId: string;
};

const filteredData = (data: GeoPhoto[], prefilter: 'all' | 'public' | 'non-public'): GeoPhoto[] => {
	const filterFunctions = {
		'non-public': (item: GeoPhoto) => !item.public,
		public: (item: GeoPhoto) => item.public,
		all: (item: GeoPhoto) => true
	};

	return data.filter(filterFunctions[prefilter]);
};

export const GeoPhotoItemStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withComputed(store => ({
		selected: computed(() => store.data().find(f => store.filter() === f._id)),
		filtered: computed((): GeoPhoto[] => filteredData(store.data(), store.preFilter())),

		all: computed(() => store.data().length),
		public: computed(() => store.data().filter(item => item.public).length),
		nonpublic: computed(() => store.data().filter(item => !item.public).length),

		activeFilter: computed(() => {
			const filter = store.preFilter();
			const filterLabels = {
				public: 'Public',
				'non-public': 'Non-public',
				all: 'All'
			};

			return filterLabels[filter] || 'All';
		}),
		activeFilterCount: computed(() => filteredData(store.data(), store.preFilter()).length)
	})),
	withMethods((state, service = inject(CategoryService)) => ({
		reset: () => patchState(state, initialState),
		resetFilter: () => {
			patchState(state, { filter: undefined });
		},
		setFilter(filter: string) {
			patchState(state, { filter });
		},

		resetPreFilter: () => {
			patchState(state, { preFilter: undefined });
		},
		setPreFilter(preFilter: 'all' | 'public' | 'non-public') {
			patchState(state, { preFilter });
		},

		createNew() {
			const data = state.data();
			patchState(state, {
				filter: 'new',
				data: [
					...data,
					{
						_id: 'new',
						appId: 'string',
						categoryId: 'string',
						id: '',
						coordinates: [0, 0],
						rotation: 0,
						title: generateLanguageObject(''),
						description: generateLanguageObject(''),
						file: undefined,
						public: true,

						createdAt: new Date(),
						updatedAt: new Date()
					}
				]
			});
		},
		patchSelected(val: Partial<GeoPhoto>) {
			{
				const data = state.data();
				const filtered = data.filter(f => state.filter() !== f._id);
				let item = data.find(f => state.filter() === f._id);
				if (!item) {
					return;
				}

				item = {
					...item,
					...val
				};

				patchState(state, {
					data: [...filtered, item]
				});
			}
		},

		getItems: rxMethod<getGeoPhotoData>(geophoto =>
			geophoto.pipe(
				tap(() => patchState(state, { isLoading: true })),
				switchMap(geoPhotoData =>
					service.getItems(geoPhotoData.appId, geoPhotoData.categoryId).pipe(
						tapResponse({
							next: data => {
								patchState(state, { data, isLoading: false });
							},
							error: () => {
								patchState(state, { data: initialState.data, isLoading: false });
							}
						})
					)
				)
			)
		)
	}))
);
